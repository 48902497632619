/* eslint-disable prettier/prettier */
import React, {useState, useEffect, createRef} from 'react';
import { Button } from 'components/Atoms/Button/Button';
import { Container } from 'components/Atoms/Container/Container';
import { ReactComponent as FileImportIcon } from 'assets/images/icons/file-import-green.svg';

import {
  InscribedTableSchema,
} from './Actions.utils';
import { sleep } from 'utils/utils';
import { DataInterface } from 'components/Atoms/Select/Select';
import { CustomRowInterface } from 'components/Organisms/Table/Table.utils';
import TasksService from '../services/TasksService';
import moment from 'moment';
import { Dialog } from '@mui/material';
import { useModal } from 'react-simple-hook-modal';
import BasicModal from 'components/Templates/BasicModal/BasicModal';
import ImportTaskerModal from 'scenes/Tasks/Actions/ImportTaskerModal';
import { toast } from 'react-toastify';
import TurnsService from 'scenes/Tasks/services/TurnsService';
import {
  ROLE_PERMISSIONS,
  verifyPermissions,
} from 'routes/ProtectedRoutes.utils';
import CreateTurnForm from './forms/CreateTurnForm';
import UpdateTurnForm from './forms/UpdateTurnForm';
import PaymentService from 'services/PaymentService';
import { transformDataForSelect } from '../Task.utils';
import StoreService from '../services/StoreService';
import SimpleTable from "components-kit/Organisms/SimpleTable/SimpleTable";
import {
  ColumnSimpleTableSchema,
  CustomSimpleTableRef,
  DataSourceSimpleTableSchema
} from "components-kit/Organisms/SimpleTable/SimpleTable.types";
import {useNavigate} from "react-router-dom";
import AttendanceTabFilterOption from "scenes/Tasks/Actions/components/AttendanceTabFilterOption";
import FilterDetail from "components-kit/Organisms/FilterDetail/FilterDetail";
import {FilterDetailData} from "components-kit/Organisms/FilterDetail/FilterDetail.types";
import {ActionSimpleTableInterface} from "components-kit/Organisms/SimpleTable/SimpleTableHeader";
import InscribedTabLoading from "scenes/Tasks/Actions/components/InscribedTabLoading";
import InscribedTabFilterOption from "scenes/Tasks/Actions/components/InscribedTabFilterOption";
import CountryService from "../../../services/CountryService";


const columns: ColumnSimpleTableSchema[] = [
  {
    title: 'ID',
    index: 'turnId',
    align: 'left',
  },
  {
    title: 'Tienda',
    index: 'storeNameFormat',
    align: 'left',
  },
  {
    title: 'Cantidad',
    index: 'spaceAvailable',
    align: 'left',
  },
  {
    title: 'Fecha',
    index: 'startOfExecutionDateFormat',
    align: 'left',
  },
  {
    title: 'Hora inicio',
    index: 'startOfExecutionTimeFormat',
    align: 'left',
  },
  {
    title: 'Hora fin',
    index: 'endOfExecutionTimeFormat',
    align: 'left',
  },
  {
    title: 'Estado asignación',
    index: 'statusFormat',
    align: 'left',
  },
  {
    title: 'Nombre y Apellido',
    index: 'fullnameTaskerFormat',
    align: 'left',
  },
  {
    title: 'Edad',
    index: 'age',
    align: 'left',
  },
  {
    title: 'Inscripción',
    index: 'formattedRegistrationDate',
    align: 'left',
  },
];

const InscribedTable = ({ taskId, permissions }: InscribedTableSchema) => {
  const mainTableRef = createRef<CustomSimpleTableRef<any>>();
  const navigate = useNavigate();
  const [tableLoading, setTableLoading] = useState(false);

  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [dataRow, setDataRow] = useState(false);
  const { isModalOpen, openModal, closeModal } = useModal();

  const [isFromCreateSingleTurn, setIsFromCreateSingleTurn] = useState(true);
  const [modalType, setModalType] = useState('create');
  const [modalData, setModalData] = useState<any>({});
  const [disabledFields, setDisabledFields] = useState(true);
  const [fieldsSelected, setFieldsSelected] = useState<any>([]);
  const [paymentMethodsList, setPaymentMethodsList] = useState<DataInterface[]>(
    []
  );
  const [attendanceList, setAttendanceList] = useState([]);
  const [storesList, setStoresList] = useState([]);
  const [modalTitle, setModalTitle] = useState(
    'Guía paso a paso para importar taskers'
  );
  const [selectedImportFile, setSelectedImportFile] = useState<File>();

  /* Filters */
  const [startDateFilter, setStartDateFilter] = useState(
      moment().add(-2, 'month').format('YYYY-MM-DD')
  );
  const [startDateFilterTemp, setStartDateFilterTemp] = useState(
      moment().add(-2, 'month').toDate()
  );
  const [endDateFilter, setEndDateFilter] = useState(
      moment().format('YYYY-MM-DD')
  );
  const [endDateFilterTemp, setEndDateFilterTemp] = useState(moment().toDate());

  const [storeIdFilter, setStoreIdFilter] = useState<number | undefined>(
      undefined
  );
  const [storeIdFilterTemp, setStoreIdFilterTemp] =
      useState<DataInterface | null>(null);

  const [statusesFilter, setStatusesFilter] = useState<string[]>([]);
  const [statusesFilterTemp, setStatusesFilterTemp] = useState<string[]>([]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [singleActionList, setSingleActionList] = useState<
      ActionSimpleTableInterface[]
      >([]);
  const [multipleActionList, setMultipleActionList] = useState<
      ActionSimpleTableInterface[]
      >([]);

  const [selected, setSelected] = useState<string[]>([]);

  const [stores, setStores] = useState<DataInterface[]>([]);
  const [statuses, setStatuses] = useState<DataInterface[]>([]);

  useEffect(() => {
    mainTableRef.current?.fetchData(1);
  }, [statusesFilter, storeIdFilter, `${startDateFilter}-${endDateFilter}`]);

  const loadTurns = async () => {
    setLoading(true);

    loadActions();

    const responseStoresList = await StoreService.getStoreByTaskId(taskId);

    if (responseStoresList.statusCode === 200) {
      setStoresList(responseStoresList.data);
    }
    const responsePaymentMethodsList = await PaymentService.getPaymentsList();
    if (responsePaymentMethodsList.statusCode === 200) {
      setPaymentMethodsList(
        transformDataForSelect(responsePaymentMethodsList.data)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    loadActions();

    const [
      storeResponse,
        statusResponse
    ] = await Promise.all([
      TurnsService.getStoresByTask(taskId),
        TurnsService.getTurnStatuses()
    ]);

    if (storeResponse.success && statusResponse.success) {
      setStores(
          storeResponse.data.map((storeRaw: any) => {
            return {
              text: storeRaw.name,
              value: `${storeRaw.id}`,
            };
          })
      );

      setStatuses(
          statusResponse.data.map((statusRaw: any) => {
            return {
              text: statusRaw.titleEs,
              value: statusRaw.id,
            };
          })
      );
    }

    setLoading(false);
  }

  const loadActions = async () => {
    const toastOption = {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    } as any;

    setSingleActionList([
      {
        value: '1',
        text: 'Ver',
        action: () => {
          toast.warning('Opción aún no disponible', toastOption);
          console.log('Ver s');
        },
      },
      {
        value: '2',
        text: 'Confirmar',
        action: () => {
          toast.warning('Opción aún no disponible', toastOption);
          console.log('Confirmar s');
        },
      },
      {
        value: '3',
        text: 'Cancelar',
        action: () => {
          toast.warning('Opción aún no disponible', toastOption);
          console.log('Cancelar s');
        },
      }
    ]);

    setMultipleActionList([
      {
        value: '21',
        text: 'Confirmar',
        action: () => {
          toast.warning('Opción aún no disponible', toastOption);
          console.log('Confirmar m');
        },
      },
      {
        value: '22',
        text: 'Cancelar',
        action: () => {
          toast.warning('Opción aún no disponible', toastOption);
          console.log('Cancelar m');
        },
      }
    ]);
  }

  const getTurnInfo = async (userId: number) => {
    const response = await TurnsService.getTurnById(userId);
    if (response.data) {
      const data = response.data;
      let newPaymentTypes = [];
      if (data.paymentTypes.length > 0) {
        newPaymentTypes = data.paymentTypes.map((paymentType: any) => {
          return {
            paymentTypeId: {
              text: paymentType.paymentTypeName,
              value: paymentType.paymentTypeId,
            },
            amount: paymentType.amount,
          };
        });
      }
      setModalData({
        store: data.storeId,
        start_date: data.startOfExecutionDateLocalFormat,
        start_date_two: data.startOfExecutionDate,
        start_time: data.startOfExecutionTimeLocalFormat,
        end_time: data.endOfExecutionTimeLocalFormat,
        people_quantity: data.lowCapacityAlertTurns,
        turn: data.turnMode,
        id: data.id,
        region: data.storeRegionName,
        storeId: data.storeId,
        city: data.storeCityName,
        paymentTypes: newPaymentTypes,
      });
      sleep(50);
    }
  };

  const turnRowOnClick = (row: any) => {
    getTurnInfo(row.id);
    setModalType('edit');
    setModalTitle('Ver turno');
    setDisabledFields(true);
    openModal();
  };

  const sendSelectedAction = async (action: string, data: any) => {
    const actionName = action === 'activate' ? 'activaron' : 'desactivaron';
    if (data.length > 0) {
      const promises = data.map(({ id }: any) => {
        return TurnsService.selectedActionByTaskId(action, id);
      });

      Promise.all(promises)
        .then(() => {
          document?.getElementById('secret-update-turns-button')?.click();

          toast.success(`Se ${actionName} los turnos satisfactoriamente`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        })
        .catch((err: any) => {
          toast.warning(`¡UPS! Un error ha ocurrido`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    }
  };

  const selectedActionsOnChange = (el: DataInterface | string | undefined) => {
    if (typeof el === 'object') {
      switch (el.value) {
        case 'view':
          setIsFromCreateSingleTurn(true);
          turnRowOnClick(fieldsSelected[0]);
          break;
        case 'edit':
          setIsFromCreateSingleTurn(true);
          getTurnInfo(fieldsSelected[0].id);
          setModalTitle('Editar turno');
          setModalType('edit');
          setDisabledFields(false);
          openModal();
          break;
        case 'activate':
          sendSelectedAction('activate', fieldsSelected);
          break;
        case 'cancel':
        case 'deactivate':
          sendSelectedAction('deactivate', fieldsSelected);
          break;
        default:
          break;
      }
    }
  };

  const storeRowOnClick = (row: any) => {
    console.log('row clicked', row);
  };

  const obtenerInscripciones = async (startDate: string, endDate: string) => {
    const responseCertificados = await TasksService.getInscriptionsByTaskId(
      taskId,
      startDate,
      endDate
    );

    if (responseCertificados.data?.data.length > 0) {
      const values = responseCertificados.data?.data.map((value: any) => {
        return {
          id: value.turnId,
          store: value.storeName,
          quantity: value.spaceAvailable,
          date: moment(value.startOfExecutionDate).format('DD-MM-YYYY'),
          time_start: moment(value.startOfExecutionDate).format('hh:mm A'),
          time_end: moment(value.endOfExecutionDate).format('hh:mm A'),
          status: { key: value.turnStatusId, text: value.turnStatusTitleEs },
          full_name: `${value.userName} ${value.userLastname}`,
          age: 20,
          inscribed: moment(value.registrationDate).format(
            'DD-MM-YYYY hh:mm A'
          ),
        };
      });
      console.log(values);
      return values;
    } else {
      return [];
    }
  };

  const loadInscriptions = async (startDate: string, endDate: string) => {
    setLoading(true);
    const inscritos = await obtenerInscripciones(startDate, endDate);
    setData(inscritos);
    setDataRow(true);
    setAttendanceList(inscritos);
    setLoading(false);
  };

  const customRows: CustomRowInterface[] = [
    {
      prop: 'status',
      row: (row: any, value: any) => {
        const style = 'filled';
        let color = 'gray';
        const textColor = '';

        if (value.key === 'SIGNED_UP') {
          color = 'yellow';
        }

        if (value.key === 'ACCEPTED') {
          color = 'green';
        }

        return (
          <div
            className={`status__main status__${style} status__${style}--bg-${color}`}
          >
            <p className={`status__text status__text--${textColor}`}>
              {value.text}
            </p>
          </div>
        );
      },
    },
    {
      prop: 'store',
      row: (row: any, value: string) => (
        <p className="hyperlink-row" onClick={() => storeRowOnClick(row)}>
          {value}
        </p>
      ),
    },
    {
      prop: 'full_name',
      row: (row: any, value: string) => (
        <p className="hyperlink-row" onClick={() => storeRowOnClick(row)}>
          {value}
        </p>
      ),
    },
  ];

  const downloadRegisteredUsersReport = async () => {
    const downloadToastId = toast.info('Iniciando la descarga ...', {
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    });

    const response = await TurnsService.downloadRegisteredUsersReportByTask(
      taskId
    );

    toast.dismiss(downloadToastId);

    if (response.data) {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `Registered_users_report_by_task_${taskId}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  const downloadBulkAcceptedRegisteredUsersInTurnsTemplate = async () => {
    const downloadToastId = toast.info('Iniciando la descarga ...', {
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    });

    const response =
      await TurnsService.downloadBulkAcceptedRegisteredUsersInTurns();

    toast.dismiss(downloadToastId);

    if (response.data) {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `Users_to_approve_template.xlsx`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  const fileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      setSelectedImportFile(e.target.files[0]);
  };

  const importOnClick = async () => {
    if (selectedImportFile) {
      const importToastId = toast.info('Cargando información ...', {
        position: 'top-center',
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });

      const response = await TurnsService.bulkAcceptedRegisteredUsersByTask(
        taskId,
        selectedImportFile
      );

      toast.dismiss(importToastId);

      if (response.data) {
        toast.success('Se aceptaron los taskers satisfactoriamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });

        const startDate = moment()
          .subtract(90, 'days')
          .startOf('day')
          .format('YYYY-MM-DD');
        const endDate = moment()
          .add(90, 'days')
          .endOf('day')
          .format('YYYY-MM-DD');
        const inscritos = await obtenerInscripciones(startDate, endDate);
        setData(inscritos);
        setDataRow(true);
        setLoading(true);
        await sleep(50);
        setLoading(false);

        closeModal();
      }
    } else {
      toast.error('Requiere cargar un archivo excel (.xlsx).', {
        position: 'top-right',
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const cancelOnClick = () => {
    setModalData({});
    closeModal();
  };

  const onSubmit = () => {
    setLoading(true);
    const startDate = moment()
      .subtract(90, 'days')
      .startOf('day')
      .format('YYYY-MM-DD');
    const endDate = moment().add(90, 'days').endOf('day').format('YYYY-MM-DD');
    loadInscriptions(startDate, endDate);
    cancelOnClick();
  };

  const selectedFieldsOnChange = (selecteds: any) => {
    const newFields = selecteds.map((rowSelected: any) => {
      const findRow = attendanceList.find(
        (turn: any) => turn.id === rowSelected.id
      );
      return findRow !== -1 ? findRow : null;
    });
    setFieldsSelected(newFields);
  };

  const importTaskersOnClick = () => {
    setIsFromCreateSingleTurn(false);
    openModal();
  };

  const fetchData = async (
      pageSize: number,
      currentPage: number
  ): Promise<DataSourceSimpleTableSchema<any>> => {
    setTableLoading(true);

    const inscriptionsResponse =
        await TasksService.getInscriptionsByTaskIdWithFilters(
            taskId,
            startDateFilter,
            endDateFilter,
            currentPage,
            pageSize,
            storeIdFilter ? [storeIdFilter] : [],
            statusesFilter
        );

    setTableLoading(false);

    if (inscriptionsResponse.success) {
      //hh:mm A
      const inscriptionsData = inscriptionsResponse.data.data.map((row: any) => {
        const style = 'filled';
        let color = 'gray';
        let textColor = '';

        if (row.turnStatusId === 'SIGNED_UP') {
          color = 'yellow';
        }

        if (row.turnStatusId === 'ACCEPTED') {
          color = 'green';
          textColor = 'white';
        }

        return {
          ...row,
          compositeKey: `${row.userId}-${row.turnId}`,
          storeNameFormat: (
              <p
                  className="hyperlink-row"
                  onClick={() => {
                    console.log('Send store id: ', row.storeId);
                    // navigate(`/stores/${row.storeId}`);
                  }}
              >{`${row.storeName}`}</p>
          ),
          startOfExecutionDateFormat: moment(row.startOfExecutionDate).format(
              'DD-MM-YYYY'
          ),
          startOfExecutionTimeFormat: moment(row.startOfExecutionDate).format(
              'hh:mm A'
          ),
          endOfExecutionTimeFormat: moment(row.endOfExecutionDate).format(
              'hh:mm A'
          ),
          fullnameTaskerFormat: (
              <p
                  className="hyperlink-row"
                  onClick={() => {
                    navigate(`/taskers/tasker-selected/${row.userId}`);
                  }}
              >{`${row.userName} ${row.userLastname}`}</p>
          ),
          age: row.userBirthday ? moment().diff(row.userBirthday, 'years') : '-',
          formattedRegistrationDate: moment(row.registrationDate).format(
              'DD-MM-YYYY hh:mm A'
          ),
          statusFormat: (
            <div
                className={`status__main status__${style} status__${style}--bg-${color}`}
            >
              <p className={`status__text status__text--${textColor}`}>
                {row.turnStatusTitleEs}
              </p>
            </div>
          )
        };
      });

      return {
        items: inscriptionsData,
        totalRows: parseInt(inscriptionsResponse.data.totalRowsFiltered),
      };
    }

    return {
      items: [],
      totalRows: 0,
    };
  };

  const clearFilters = () => {
    setStoreIdFilter(undefined);
    setStatusesFilter([]);
  };

  const removeFilter = (key: string) => {
    switch (key) {
      case 'STORE_FILTER':
        setStoreIdFilter(undefined);
        break;
      case 'STATUSES_FILTER':
        setStatusesFilter([]);
        break;
    }
  };

  const getActiveFilters = (): FilterDetailData[] => {
    const sd = moment(startDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const ed = moment(endDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY');

    const filters: FilterDetailData[] = [
      {
        key: 'DATE_RANGE_FILTER',
        label: 'Fecha',
        detail: `Del ${sd} al ${ed}`,
        disableRemove: true,
      },
    ];

    if (storeIdFilter) {
      const storeFilter = stores.find((storeLocal) => {
        return Number.parseInt(storeLocal.value) === storeIdFilter;
      });

      if (storeFilter) {
        filters.push({
          key: 'STORE_FILTER',
          label: 'Tienda',
          detail: storeFilter.text,
        });
      }
    }

    if(statusesFilter.length > 0) {
      const statusesSelectedLocal = statuses.filter((statusRow) => {
        return statusesFilter.includes(statusRow.value);
      }).map((statusRow) => {
        return statusRow.text
      });

      filters.push({
        key: 'STATUSES_FILTER',
        label: statusesFilter.length === 1 ? `Estado`: `Estados`,
        detail: statusesSelectedLocal.join(', '),
      });
    }

    return filters;
  };

  const executeAdditionalFilters = async (
      startDateParam: string,
      endDateParam: string,
      storeParam: number | undefined,
      statusesParam: string[] = []
  ) => {
    setStartDateFilter(startDateParam);
    setEndDateFilter(endDateParam);
    setStoreIdFilter(storeParam);
    setStatusesFilter(statusesParam)
  };

  return (
    <>
      <Container>
        <div className="inscribed__main">
          {!loading &&
              verifyPermissions(
                  permissions,
                  ROLE_PERMISSIONS.tasks.LOOK_SUBSCRIBED_USER_TURN
              ) && (
                  <>
                    <div className="header__options">
                      <div
                          className="first__options"
                      >
                        <div className="searchbar__main">
                          <div className="searchbar__content">
                            <Button
                                className="additional__buttons__simple__table"
                                onClick={() => { importTaskersOnClick() }}
                                text={'Importar taskers'}
                                type="primary"
                                startIcon={<FileImportIcon />}
                                variant="outlined"
                                border="square"
                                large
                            />
                          </div>
                        </div>
                      </div>
                      <div className="second__options">
                        <InscribedTabFilterOption
                            isFilterOpen={isFilterOpen}
                            setIsFilterOpen={setIsFilterOpen}
                            startDate={startDateFilterTemp}
                            currentStartDate={startDateFilter}
                            setStartDate={setStartDateFilterTemp}
                            endDate={endDateFilterTemp}
                            currentEndDate={endDateFilter}
                            setEndDate={setEndDateFilterTemp}
                            submit={executeAdditionalFilters}
                            storeId={storeIdFilterTemp}
                            setStoreId={setStoreIdFilterTemp}
                            currentStoreId={storeIdFilter}
                            statusesSelected={statusesFilterTemp}
                            setStatusesSelected={setStatusesFilterTemp}
                            currentStatusesSelected={statusesFilter}
                            stores={stores}
                            statuses={statuses}
                        />
                      </div>
                    </div>

                    {/* filter details */}
                    <FilterDetail
                        filters={getActiveFilters()}
                        cleanFilters={clearFilters}
                        removeFilter={removeFilter}
                        loading={tableLoading}
                    />

                    <SimpleTable
                        ref={mainTableRef}
                        columns={columns}
                        request={fetchData}
                        initialDefaultPageSize={5}
                        loading={tableLoading}
                        minWidth={1500}
                        selected={selected}
                        setSelected={setSelected}
                        singularSelectedItemTitle="inscripción"
                        pluralSelectedItemTitle="inscripciones"
                        singleActionsTable={singleActionList}
                        multiActionsTable={multipleActionList}
                        id="compositeKey"
                    />
                  </>
              )}
          {
            loading && <InscribedTabLoading />
          }

          <Dialog
            className="modal__main turns__modal__main"
            onClose={closeModal}
            open={isModalOpen}
          >
            <BasicModal title={modalTitle} closeModal={closeModal}>
              {isFromCreateSingleTurn ? (
                <div className="turns-form__main">
                  {modalType === 'create' ? (
                    <CreateTurnForm
                      disabledFields={disabledFields}
                      cancelOnClick={cancelOnClick}
                      taskId={taskId}
                      storesList={storesList}
                      paymentMethodsList={paymentMethodsList}
                      onSubmit={onSubmit}
                    />
                  ) : modalData.id ? (
                    <UpdateTurnForm
                      disabledFields={disabledFields}
                      cancelOnClick={cancelOnClick}
                      taskId={taskId}
                      storesList={storesList}
                      paymentMethodsList={paymentMethodsList}
                      data={modalData.id ? modalData : {}}
                      onSubmit={onSubmit}
                    />
                  ) : null}
                </div>
              ) : (
                <div className="create-multi-turns__main">
                  <ImportTaskerModal
                    fileOnChange={fileOnChange}
                    downloadTemplateOnClick={
                      downloadBulkAcceptedRegisteredUsersInTurnsTemplate
                    }
                    downloadRegisteredUsersReportOnClick={
                      downloadRegisteredUsersReport
                    }
                    importOnClick={importOnClick}
                  />
                </div>
              )}
            </BasicModal>
          </Dialog>
        </div>
      </Container>
    </>
  );
};

export default InscribedTable;
