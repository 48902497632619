import React, { Suspense, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-simple-hook-modal/dist/styles.css';
import { ModalProvider } from 'react-simple-hook-modal';
import ScrollToTop from 'components/Atoms/ScrollToTop/ScrollToTop';
import setUpInterceptor from 'utils/interceptor';
import i18n from 'utils/i18n';
import { theme } from 'App.utils';
import AppRoutes from 'routes/routes';
import Loader from 'components/Atoms/Loader/Loader';
import { useDispatch, useSelector, useStore } from 'react-redux';
import AlertMessageModal from 'components/Atoms/AlertMessageModal/AlertMessageModal';
import 'react-toastify/dist/ReactToastify.css';
import AppService from './services/AppService';
import AuthAction from 'redux/actions/authActions/actions';

//resources
import 'assets/styles/global.scss';

const App = () => {
  const store = useStore();
  const { token } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [loadingInitValues, setLoadingInitValues] = useState(true);
  i18n.changeLanguage('es');

  setUpInterceptor(store);

  useEffect(() => {
    verifyUserSession();
  }, [dispatch, store]);

  const verifyUserSession = async () => {
    dispatch(AuthAction.signOut({}));

    if (token) {
      const verifySessionResponse = await AppService.verifySession(token);

      if (verifySessionResponse.success) {
        dispatch(AuthAction.signIn(verifySessionResponse.data));
      }
    }

    setLoadingInitValues(false);
  };

  return (
    <>
      {!loadingInitValues ? (
        <Suspense fallback={<Loader />}>
          <ScrollToTop>
            <ThemeProvider theme={theme}>
              <ModalProvider backdropClassName="modal-provider__main">
                <AlertMessageModal />
                <AppRoutes />
                <ToastContainer />
              </ModalProvider>
            </ThemeProvider>
          </ScrollToTop>
        </Suspense>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default App;
