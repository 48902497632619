import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker';
import { es } from 'date-fns/locale';
import './Datepicker.scss';

interface DatepickerInferface {
  label?: string;
  placeholder?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export default function DatePicker({
  label = '',
  placeholder = 'Seleccionar fecha',
  value,
  onChange,
  disabled = false,
  minDate = undefined,
  maxDate = undefined,
}: DatepickerInferface) {
  //const [value, setValue] = React.useState<Date | null>(null);
  const dateOnChange = (date: Date | null) => {
    onChange(date);
  };
  return (
    <div className="date-picker__main">
      <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
        {label !== '' && <p className={`textfield__label`}>{`${label}`}</p>}
        <MaterialDatePicker
          minDate={minDate}
          maxDate={maxDate}
          inputFormat={'dd/MM/yyyy'}
          toolbarPlaceholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={dateOnChange}
          renderInput={(params) => (
            <TextField
              className="text-field"
              {...params}
              placeholder={placeholder}
            />
          )}
          components={{
            OpenPickerIcon: DateRangeIcon,
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
