import React, { useState } from 'react';
import { Button } from 'components/Atoms/Button/Button';
import Select from 'components/Atoms/Select/Select';
import TextInput from 'components/Atoms/TextInput/TextInput';
import DatePicker from 'components/Molecules/Datepicker/Datepicker';
import InputNumber from 'components/Molecules/InputNumber/InputNumber';
import TimePicker from 'components/Molecules/TimePicker/TimePicker';
import useForm from 'hooks/useForm';
import moment from 'moment';
import TurnsService from 'scenes/Tasks/services/TurnsService';
import { transformDataForSelect } from '../../Task.utils';
import { toast } from 'react-toastify';
import { OnChangeValueType } from 'utils/utils';
import dayjs, { Dayjs } from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import { INITIAL_EMPTY_FORM_VALUES, TIMEZONE_LIST } from './forms.utils';

interface CreateTurnInterface {
  disabledFields: boolean;
  cancelOnClick: () => void;
  onSubmit: () => void;
  taskId: number;
  storesList: any;
  paymentMethodsList: any;
  regionTitle?: string;
  cityTitle?: string;
}

const CreateTurnForm = ({
  cancelOnClick,
  taskId,
  storesList,
  paymentMethodsList,
  onSubmit,
  regionTitle = 'Región',
  cityTitle = 'Ciudad',
}: CreateTurnInterface) => {
  const [loading, setLoading] = useState(true);
  const [commune, setCommune] = useState('');
  const [region, setRegion] = useState('');
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const { form, onChange, validFields } = useForm(INITIAL_EMPTY_FORM_VALUES);
  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    const newErrors: any = validFields();

    if ('original_quantity_required' in newErrors) {
      delete newErrors.original_quantity_required;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const paymentTypes: any[] = [];
      if (paymentMethods.length > 0) {
        paymentMethods.forEach((payment: any, index: number) => {
          paymentTypes.push({
            paymentTypeId: payment[`payment_method-${index}`].value,
            amount: Number.parseInt(payment[`mount-${index}`]),
          });
        });
      }
      const payload = {
        taskId: taskId,
        storeId: form.store.value,
        executionDate: moment(form.start_date).format('YYYY-MM-DD'),
        executionStartTime: moment(form.start_time).format('HH:mm'),
        executionEndTime: moment(form.end_time).format('HH:mm'),
        availableTurns: Number.parseInt(`${form.people_quantity}`),
        lowCapacityAlertTurns: Number.parseInt(`${form.people_quantity}`),
        turnMode: form.turn.value,
        paymentTypes: paymentTypes,
        originalQuantityRequired: form.original_quantity_required
          ? Number.parseInt(`${form.original_quantity_required}`)
          : undefined,
      };

      const response = await TurnsService.saveTurnByTask(payload);
      if (response.statusCode === 200) {
        toast.success('Se generó el turno satisfactoriamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        onSubmit();
      } else {
        toast.warning('Ocurrió un error creando el nuevo turno', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    }
  };

  const setRegionFields = (newValue?: OnChangeValueType | undefined) => {
    let newRegion = '';
    let newCommune = '';
    setLoading(true);

    if (
      !Array.isArray(newValue) &&
      typeof newValue === 'object' &&
      Object.keys(newValue).length > 0
    ) {
      const findStoreSelected: any = storesList.find(
        (store: any) => store.id === Number.parseInt(newValue.value)
      );
      if (findStoreSelected) {
        newCommune = findStoreSelected.provinceName;
        newRegion = findStoreSelected.regionName;
      }
    }
    setCommune(newCommune);
    setRegion(newRegion);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const paymentMethodOnChange = (
    newValue: OnChangeValueType,
    name: string,
    index: number
  ) => {
    paymentMethods[index][name] = newValue;
  };

  const addPaymentTypeOnClick = () => {
    const index = paymentMethods.length;
    const newPaymentMethods = [...paymentMethods];
    newPaymentMethods.push({
      [`payment_method-${index}`]: '',
      [`mount-${index}`]: '',
    });

    setPaymentMethods(newPaymentMethods);
  };

  return (
    <form method="POST" onSubmit={onSubmitForm}>
      <div className="fields-group fields-group--cols-2 fields-group--rows-2">
        <div className="field-item">
          <Select
            data={transformDataForSelect(storesList || [])}
            errors={errors}
            label={'Tienda'}
            name="store"
            onChange={(_e: any, newValue?: OnChangeValueType) => {
              onChange(newValue || '', 'store');
              setRegionFields(newValue || '');
            }}
            placeholder={'Selecciona una tienda'}
            required
          />
        </div>
        <div className="field-item">
          <TextInput
            className="view__task__turn__input"
            label={regionTitle}
            name={`region`}
            value={!loading ? region : ''}
            disabled
            placeholder={`${regionTitle} de la tienda`}
          />
        </div>
        <div className="field-item">
          <TextInput
            className="view__task__turn__input"
            label={cityTitle}
            name={`commune`}
            value={!loading ? commune : ''}
            disabled
            placeholder={`${cityTitle} de la tienda`}
          />
        </div>
        <div className="field-item">
          <DatePicker
            label="Fecha"
            onChange={(newValue?: Date | null) => {
              if (newValue) onChange(newValue.toString(), 'start_date');
              else onChange('', 'start_date');
            }}
            value={new Date(form.start_date)}
          />
        </div>
        <div className="field-item">
          <TimePicker
            label="Hora inicio"
            name="start_time"
            value={dayjs(form.start_time)}
            placeholder={'Selecciona una hora de inicio'}
            onChange={(newValue?: Dayjs | null) => {
              if (newValue) onChange(newValue.toString(), 'start_time');
              else onChange('', 'start_time');
            }}
            errors={errors}
          />
        </div>
        <div className="field-item">
          <TimePicker
            label="Hora final"
            name="end_time"
            value={dayjs(form.end_time)}
            placeholder={'Selecciona una hora final'}
            onChange={(newValue?: Dayjs | null) => {
              if (newValue) onChange(newValue.toString(), 'end_time');
              else onChange('', 'end_time');
            }}
            errors={errors}
          />
        </div>
        <div className="field-item">
          <InputNumber
            className="view__task__turn__input"
            errors={errors}
            label={'Cantidad de personas'}
            name="people_quantity"
            onChange={(value: string, field: any) => {
              if (value) onChange(value, field || 'people_quantity');
              else onChange('', field || 'people_quantity');
            }}
          />
        </div>
        <div className="field-item">
          <Select
            data={TIMEZONE_LIST}
            errors={errors}
            label={'Turno'}
            name="turn"
            onChange={(_e: any, newValue?: OnChangeValueType) => {
              if (newValue) onChange(newValue, 'turn');
              else onChange('', 'turn');
            }}
            placeholder={'Selecciona un turno'}
            required
          />
        </div>
        <div className="field-item">
          <InputNumber
            className="view__task__turn__input"
            label={'Requerimiento del cliente'}
            required={false}
            name="original_quantity_required"
            defaultValue={form.original_quantity_required as any}
            onChange={(value: string, field: any) => {
              if (value) onChange(value, field || 'original_quantity_required');
              else onChange('', field || 'original_quantity_required');
            }}
          />
        </div>
        <div className="field-item"></div>
        {paymentMethods?.length > 0 &&
          paymentMethods.map((paymentMethod: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <div className="field-item">
                  <Select
                    data={paymentMethodsList}
                    errors={errors}
                    label={'Tipo de pago'}
                    name={`payment_method-${index}`}
                    onChange={(_e: any, newValue?: OnChangeValueType) => {
                      if (newValue) {
                        paymentMethodOnChange(
                          newValue,
                          `payment_method-${index}`,
                          index
                        );
                      } else
                        paymentMethodOnChange(
                          '',
                          `payment_method-${index}`,
                          index
                        );
                    }}
                    placeholder={'Selecciona un turno'}
                    value={paymentMethod.paymentTypeId}
                  />
                </div>
                <div className="field-item">
                  <TextInput
                    className="view__task__turn__input"
                    errors={errors}
                    label={'Monto'}
                    name={`mount-${index}`}
                    onChange={({ target }) =>
                      paymentMethodOnChange(
                        target.value,
                        `mount-${index}`,
                        index
                      )
                    }
                    placeholder={'Escribe el monto del método de pago'}
                    defaultValue={paymentMethod.amount}
                  />
                </div>
              </React.Fragment>
            );
          })}
        <div className="field-item" />
        <div className="field-item">
          <div className="select__button" onClick={addPaymentTypeOnClick}>
            <p>Agregar tipo de pago</p>
            <AddIcon />
          </div>
        </div>
      </div>
      <div className="extra__buttons__main">
        <Button
          onClick={cancelOnClick}
          text={'Cancelar'}
          type="primary"
          size="large"
          border="circular"
        />
        <Button
          text={'Guardar'}
          type="submit"
          size="large"
          variant="contained"
          border="circular"
          submit
        />
      </div>
    </form>
  );
};

export default CreateTurnForm;
